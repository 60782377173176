<template>
  <v-container
    id="latest-rounds-board"
    class="pa-0 text-center"
    fluid>
    <div class="header">
      {{ $t('views.roulette.latestRoundsBoard.title') }}
    </div>
    <v-row
      v-for="(round, index) in getLastestRounds"
      :key="index"
      no-gutters>
      <v-col cols="3">
        <v-card
          color="green"
          outlined>
          {{ round.sequence }}
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card
          :color="numColor[round.result]"
          outlined>
          {{ round.result }}
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const MAX_ROUNDS = 7;

export default {
	name: "LatestRoundsBoard",
	props: {
		numColor: {
			type: Object,
			required: true,
		},
		rounds: {
			type: Array,
			required: true,
		},
	},
	computed: {
		getLastestRounds() {
			return this.rounds.slice(0, MAX_ROUNDS);
		}
	}
};
</script>

<style lang="scss">
	#latest-rounds-board {
		color: white;

		table, th, td {
			border: 1px solid black;
		}

		.v-card {
			color: white;
			padding: 2px;
			border: 1px black solid !important;
		}
	}
</style>
