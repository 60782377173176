<template>
  <div>
    <div class="text-h6">
      {{ $t("views.tickets.create.round") }}: {{ roundSequence }} <automatic-round-time-left :automatic-round="automaticRound" />
    </div>
    <div
      v-if="round.value.timeLeft"
      class="text-subtitle-1">
      <font-awesome-icon icon="clock" />
      : {{ round.value.timeLeft }}
    </div>
  </div>
</template>

<script>
import AutomaticRoundTimeLeft from "@/components/roulette/AutomaticRoundTimeLeft.vue";

export default {
  name: "RoundInformation",
  components: {
    AutomaticRoundTimeLeft,
  },
  props: {
    round: {
      type: Object,
      required: true,
    },
    automaticRound: {
      type: Object,
      required: true,
    },
  },
  computed: {
    roundSequence() {
      const round = this.round.value;
      if (round.id !== null) {
        return round.sequence;
      }
      return "- - - -";
    },
  },
};
</script>

<style scoped>

</style>
