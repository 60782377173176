import httpClient from "./httpClient";
import RoundUpdateCategory from '../imported/RoundUpdateCategory';

const resource = "rounds";

export function createRound() {
  return httpClient.post(`/${resource}`);
}

export function noMoreBets(id) {
  return httpClient.put(`/${resource}/${id}`, {
    category: RoundUpdateCategory.NoMoreBets,
    round: { id },
  });
}

export function roundOver(id){
  return httpClient.put(`/${resource}/${id}`, {
    category: RoundUpdateCategory.RoundOver,
    round: { id },
  });
}
