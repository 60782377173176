<template>
  <v-container
    id="plays-board"
    class="pa-0 text-center"
    fluid>
    <div class="header">
      {{ $t('views.roulette.playsBoard.numbersTable') }}
    </div>
    <v-row
      v-for="num in 6"
      :key="num"
      no-gutters>
      <v-col cols="1">
        <v-card
          :color="numColor[num]"
          outlined>
          {{ num }}
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.numbers[num] || 0 }}
        </v-card>
      </v-col>

      <v-col cols="1">
        <v-card
          :color="numColor[num + 6]"
          outlined>
          {{ num + 6 }}
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.numbers[num + 6] || 0 }}
        </v-card>
      </v-col>

      <v-col cols="1">
        <v-card
          :color="numColor[num + 12]"
          outlined>
          {{ num + 12 }}
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.numbers[num + 12] || 0 }}
        </v-card>
      </v-col>

      <v-col cols="1">
        <v-card
          :color="numColor[num + 18]"
          outlined>
          {{ num + 18 }}
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.numbers[num + 18] || 0 }}
        </v-card>
      </v-col>

      <v-col cols="1">
        <v-card
          :color="numColor[num + 24]"
          outlined>
          {{ num + 24 }}
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.numbers[num + 24] || 0 }}
        </v-card>
      </v-col>

      <v-col cols="1">
        <v-card
          :color="numColor[num + 30]"
          outlined>
          {{ num + 30 }}
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.numbers[num + 30] || 0 }}
        </v-card>
      </v-col>

    </v-row>
    <v-row no-gutters>
      <v-col cols="6">
        <v-card
          :color="colors.green"
          outlined>
          0
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          color="green"
          outlined>
          {{ plays.numbers[0] || 0 }}
        </v-card>
      </v-col>
    </v-row>

    <div class="header">
      {{ $t('views.roulette.playsBoard.dozensTable') }}
    </div>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card
          color="indigo"
          outlined>
          1 - 12
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.dozen.first || 0 }}
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          color="indigo"
          outlined>
          13 - 24
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.dozen.second || 0 }}
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          color="indigo"
          outlined>
          25 - 36
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-card
          color="green"
          outlined>
          {{ plays.dozen.third || 0 }}
        </v-card>
      </v-col>
    </v-row>

    <div class="header">
      {{ $t('views.roulette.playsBoard.latestRoundsTable') }}
    </div>
    <v-row no-gutters>
      <v-col cols="4">
        <v-card
          :color="colors.red"
          outlined>
          {{ plays.red || 0 }}
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          :color="colors.black"
          outlined>
          {{ plays.black || 0 }}
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          :color="colors.green"
          outlined>
          {{ plays.green || 0 }}
        </v-card>
      </v-col>
    </v-row>

    <div class="header">
      {{ $t('views.roulette.playsBoard.hotColdTable') }}
    </div>
    <v-row no-gutters>
      <v-col cols="4">
        <v-card
          color="red"
          outlined>
          {{ $t('views.roulette.playsBoard.hot') }}
        </v-card>
      </v-col>
      <v-col
        v-for="num in getHotPlays"
        :key="num">
        <v-card
          color="indigo"
          outlined>
          {{ num || '-' }}
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="4">
        <v-card
          color="blue"
          outlined
          tile>
          {{ $t('views.roulette.playsBoard.cold') }}
        </v-card>
      </v-col>
      <v-col
        v-for="num in getColdPlays"
        :key="num">
        <v-card
          color="indigo"
          outlined>
          {{ num }}
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import lodash from "lodash";
  import { numorder } from "@/roulette/wheelspin.js";

  const MAX_HOT_COLD_NUMBERS = 5;

	const playsTemplate = {
    numbers: {},
    dozen: {
      first: 0,
      second: 0,
      third: 0
    },
    red: 0,
    black: 0,
    green: 0,
    hot: [],
    cold: [],
  };

	export default {
		name: "PlaysBoard",
		props: {
			colors: {
				type: Object,
				required: true,
				default: () => {}
			},
			numColor: {
				type: Object,
				required: true,
				default: () => {}
			},
			rounds: {
				type: Array,
				required: true,
				default: () => [],
			},
		},
    data() {
      return {
        plays: { ...playsTemplate },
        sortedPlays: [],
      };
    },
    computed: {
      getHotPlays() {
        const numbers = this.sortedPlays.filter(([,v]) => v > 0).map(([k,]) => k);
        for(let i = numbers.length; i <= MAX_HOT_COLD_NUMBERS; i++){
          numbers.push(null);
        }
        return numbers.slice(0, MAX_HOT_COLD_NUMBERS);
      },
      getColdPlays() {
        const numbers = this.sortedPlays.length ? this.sortedPlays.map(([k,]) => k) : numorder;
        return numbers.slice(Math.max(numbers.length - MAX_HOT_COLD_NUMBERS, 0)).reverse();
      }
    },
    watch: {
      rounds:{
        handler(rounds) {
          const context = this;
          const playsDeepCopy = lodash.cloneDeep(playsTemplate);

          // start with all numbers at 0
          for(let i = 0; i <= 36; i++){
            playsDeepCopy.numbers[i] = 0;
          }

          const plays = rounds.filter(x => x !== null).reduce((prev, current) => {
            const result = current.result;
            prev.numbers[result]++;

            // Dozen
            if(result > 24) prev.dozen.third++;
            else if(result > 12) prev.dozen.second++;
            else if(result > 0) prev.dozen.first++;

            // Colors
            if(context.numColor[result] === context.colors.red) prev.red++;
            else if(context.numColor[result] === context.colors.black) prev.black++;
            else prev.green++;

            return prev;
          }, playsDeepCopy);

          // get sorted list of all numbers
          const sortableEntries = Object.entries(plays.numbers)
            .sort(([a,],[b,]) => numorder.indexOf(parseInt(a))-numorder.indexOf(parseInt(b)))
            .sort(([,a],[,b]) => b-a);

          this.sortedPlays = sortableEntries;
          this.plays = plays;
        },
        immediate: true
      }
    },
	};
</script>

<style lang="scss">
	#plays-board {
		color: white;
    font-size: 0.8em;

		table, th, td {
			border: 1px solid black;
		}

		.v-card {
			color: white;
			padding: 1px;
			border: 1px black solid !important;
		}
	}
</style>
