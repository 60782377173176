<template>
  <div>
    <div class="jackpot">
      <div class="jackpot-header">
        <img :src="jackpotData.header">
      </div>
      <div class="jackpot-win-left">
        <v-scroll-x-transition>
          <img
            v-if="showWin"
            :src="jackpotData.winLeft">
        </v-scroll-x-transition>
      </div>
      <div
        class="jackpot-win-right">
        <v-scroll-x-reverse-transition>
          <img
            v-if="showWin"
            :src="jackpotData.winRight">
        </v-scroll-x-reverse-transition>
      </div>
      <div
        v-for="(slot, index) in slots"
        :key="index"
        :class="{'jackpot-start-slot': index === 0,
                 'jackpot-center-slot': index !== 0 && index !== slots.length - 1,
                 'jackpot-end-slot': index === slots.length - 1}">
        <img :src="getBackgroundImageFor(index)">
        <img
          class="jackpot-slot-numbers"
          :src="numbers[slot]">
      </div>
    </div>
  </div>
</template>

<script>
import confetti from "canvas-confetti";
import Swal from "sweetalert2";

export default {
  name: "Jackpot",
  props: {
    balance: {
      type: [Number, String],
      default: () => 0,
    },
    showWin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slots: [0, 0, 0, 0],
      jackpotData: {
        header: "./img/jackpot/jackpot.png",
        startSlot: "./img/jackpot/jackpot-start-slot.png",
        centerSlot: "./img/jackpot/jackpot-center-slot.png",
        endSlot: "./img/jackpot/jackpot-end-slot.png",
        winRight: "./img/jackpot/jackpot-win-right.png",
        winLeft: "./img/jackpot/jackpot-win-left.png",
      },
      numbers: {
        0: "./img/jackpot/0.png",
        1: "./img/jackpot/1.png",
        2: "./img/jackpot/2.png",
        3: "./img/jackpot/3.png",
        4: "./img/jackpot/4.png",
        5: "./img/jackpot/5.png",
        6: "./img/jackpot/6.png",
        7: "./img/jackpot/7.png",
        8: "./img/jackpot/8.png",
        9: "./img/jackpot/9.png",
      },
    };
  },
  watch: {
    showWin(value) {
      if (value) {
        this.showJackpotWinnerAnimation();
      }
    },
    balance() {
      this.updateSlots();
    },
  },
  created() {
    this.updateSlots();
  },
  methods: {
    showJackpotWinnerAnimation() {
      const duration = 10 * 1000;
      Swal.fire({
        customClass: {
          container: 'jackpot-winner-centered-bg'
        },
        width: 600,
        showConfirmButton: false,
        background: 'transparent',
        backdrop: 'rgba(0,0,0,.4) url(./img/jackpot/jackpot-winner-bg.png) center no-repeat',
        imageUrl: './img/jackpot/jackpot-winner.png',
        timer: duration
      });

      const animationEnd = Date.now() + duration;
      const defaults = {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 0,
      };

      const randomInRange = (min, max) => {
        return Math.random() * (max - min) + min;
      }

      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          this.$emit('animation-end');
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(Object.assign({}, defaults, {
          particleCount,
          origin: {
            x: randomInRange(0.1, 0.3),
            y: Math.random() - 0.2,
          },
        }));
        confetti(Object.assign({}, defaults, {
          particleCount,
          origin: {
            x: randomInRange(0.7, 0.9),
            y: Math.random() - 0.2,
          },
        }));
      }, 250);
    },
    getBackgroundImageFor(index) {
      if (index === 0) {
        return this.jackpotData.startSlot;
      } else if (index === this.slots.length - 1) {
        return this.jackpotData.endSlot;
      }

      return this.jackpotData.centerSlot;
    },
    reverseString(str) {
      return str.split("").
        reverse().
        join("");
    },
    updateSlots() {
      const balanceString = this.balance.toString();
      if (balanceString.length < 4) {
        this.slots = balanceString.padStart(4, "0").
          split("");
      } else {
        this.slots = balanceString.split("");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.jackpot {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 90px;
}

.jackpot-header {
  width: 100%;
  position: absolute;
  bottom: 70%;
  z-index: 10;
}

.jackpot-win-left, .jackpot-win-right {
  position: absolute;
  z-index: 15;
  top: 0;
  width: 25%;
  height: 100%;

  img {
    height: 100%;
  }
}

.jackpot-win-left {
  left: -5%;
}

.jackpot-win-right {
  right: -5%;
}

.jackpot-start-slot {
  position: relative;

  .jackpot-slot-numbers {
    left: 65%;
  }
}

.jackpot-center-slot {
  position: relative;

  .jackpot-slot-numbers {
    width: 80%;
  }
}

.jackpot-end-slot {
  position: relative;

  .jackpot-slot-numbers {
    left: 35%;
  }
}

img:not(.jackpot-slot-numbers) {
  width: 100%;
}

.jackpot-slot-numbers {
  position: absolute;
  margin: auto;
  width: 58%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
