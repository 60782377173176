import { EventEmitter } from "events";
import anime from "animejs/lib/anime.es.js";

export const WheelStatuses = {
  IDLE: "IDLE",
  SPINNING: "SPINNING",
  COMPLETED: "COMPLETED",
};

const BALL_SELECTOR = ".wprw-ball",
  WHEEL_SPINNER_SELECTOR = ".wprw-pieContainer";

export const numorder = [
  0,
  32,
  15,
  19,
  4,
  21,
  2,
  25,
  17,
  34,
  6,
  27,
  13,
  36,
  11,
  30,
  8,
  23,
  10,
  5,
  24,
  16,
  33,
  1,
  20,
  14,
  31,
  9,
  22,
  18,
  29,
  7,
  28,
  12,
  35,
  3,
  26,
];

function calculateNumbersLocations() {
  const numbersLocation = [];
  const arc = 360 / numorder.length;
  for (let i = 0; i < numorder.length; i++) {
    numbersLocation[numorder[i]] = [];
    numbersLocation[numorder[i]][0] = i * arc;
    numbersLocation[numorder[i]][1] = i * arc + arc;
  }
  return numbersLocation;
}

class Wheel extends EventEmitter {
  constructor() {
    super();
    this.numbersLocation = calculateNumbersLocations();
    this.audio = null;
    this._status = WheelStatuses.IDLE;
    this.preloadAudio();
  }

  get status() {
    return this._status;
  }

  beginWheelSpin(luckyNumber) {
    const context = this;

    setTimeout(function() {
      context._status = WheelStatuses.SPINNING;
      context.emit("statusChanged");
      context.spinTo(luckyNumber);
      context.completedFunction(luckyNumber);
    }, 500);
  }

  completedFunction(luckyNumber) {
    const context = this;
    setTimeout(function() {
      context._status = WheelStatuses.COMPLETED;
      $("#result-display span").
        html(luckyNumber);
      $("#result-display").
        removeClass("hide");
      context.emit("statusChanged");
    }, 15000);
  }

  preloadAudio() {
    this.audio = new Audio("/sounds/RouletteWheel1.mp3");
  }

  playSound() {
    this.audio.volume = 0.6;
    this.audio.play();
  }

  // Resets the animation to its default state
  resetAnimation() {
    this._status = WheelStatuses.IDLE;
    this.emit("statusChanged");

    anime({
      targets: BALL_SELECTOR,
      rotate: "0deg",
      translateY: ["5em", "0em"],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 5000,
    });

    anime({
      targets: WHEEL_SPINNER_SELECTOR,
      rotate: "0deg",
      easing: "cubicBezier(.5, .05, .1, .3)",
      delay: 1000,
      duration: 7000,
    });

    $("#result-display").
      addClass("hide");
  }

  // Begins spinning the wheel to the num $argument
  spinTo(num) {
    const temp = this.numbersLocation[num][0] + 4, //get location
      randomSpace = Math.floor(Math.random() * 360 + 1), //randomize
      context = this;

    context.spinWheel(randomSpace);
    context.ballRotateTo(randomSpace + temp);
  }

  // The animation that rotates and translates the ball inward
  ballRotateTo(deg) {
    const ballSpinTime = 4;
    const destination = -360 * ballSpinTime - (360 - deg);

    const animationsTimeline = anime.timeline({
      targets: BALL_SELECTOR,
    });
    animationsTimeline.add({
      rotate: [0, -2880],
      easing: "cubicBezier(0.15,0,1,1)",
      duration: 4000,
    });
    animationsTimeline.add({
      rotate: [0, destination],
      translateY: ["0em", "5em"],
      easing: "cubicBezier(0, 0, 0.58, 1.0)",
      duration: 4000,
    });
    animationsTimeline.add({
      rotate: [destination, destination + 700],
      easing: "cubicBezier(0, 0, 0.58, 1.0)",
      duration: 7000,
    });
  }

  // The animation that rotates the inner wheel / red & black numbers
  // Multiple keyframes and setTimeouts control the wheel rotation at every stage
  spinWheel(deg) {
    const wheelSpinTime = 2;
    const destination = 360 * wheelSpinTime + deg;
    const context = this;

    // Alternative
    // anime({
    //   targets: WHEEL_SPINNER_SELECTOR,
    //   rotate: [
    //     {
    //       value: destination,
    //       easing: "cubicBezier(0.42, 0, 1.0, 1.0)",
    //       duration: 8000,
    //     },
    //     {
    //       value: destination + 700,
    //       easing: "cubicBezier(0, 0, 0.58, 1.0)",
    //       duration: 7000,
    //     },
    //   ],
    //   duration: 15000,
    // });

    const animationsTimeline = anime.timeline({
      targets: WHEEL_SPINNER_SELECTOR,
    });

    animationsTimeline.add({
      rotate: [0, destination],
      duration: 8000,
      easing: "cubicBezier(0.42, 0, 1.0, 1.0)",
      begin() {
        context.playSound();
      },
    });

    animationsTimeline.add({
      rotate: [destination, destination + 700],
      duration: 7000,
      easing: "cubicBezier(0, 0, 0.58, 1.0)",
    });
  }
}

export default Wheel;
