<template>
  <v-chip v-if="automaticRound.secondsLeftUntilNext != null">
    {{ automaticRound.secondsLeftUntilNext }}
  </v-chip>
</template>

<script>
export default {
  name: "AutomaticRoundTimeLeft",
  props: {
    automaticRound: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>

</style>
