<template>
  <v-container
    id="container"
    fluid>
    <v-progress-linear
      :value="progressPercentage"
      class="round-progress"
      height="30"
      dark>
      <strong>{{ Math.ceil(progressPercentage) }}%</strong>
    </v-progress-linear>
    <round-information
      class="round-info-wrapper"
      :round="currentRound"
      :automatic-round="automaticRound" />
    <v-row
      id="roulette-and-info-wrapper"
      justify="space-between"
      no-gutters>
      <v-col
        class="d-flex flex-column justify-center position-relative"
        cols="12"
        md="7"
        lg="9">
        <img
          v-if="image"
          class="spin-to-win"
          :src="image">
        <img
          v-else
          class="spin-to-win"
          src="/img/spin-to-win.png"
          alt="spin-to-win-image">
        <jackpot
          v-if="jackpot.value.enable"
          id="jackpot"
          :balance="jackpot.value.balance"
          :show-win="jackpot.value.won"
          @animation-end="jackpot.value.won = false" />
        <div class="wprw-spinner wprw_customwheelsize">
          <div
            id="wprw-ball"
            class="wprw-ball">
            <span />
          </div>
          <div class="wprw-platebg">
            <div
              id="result-display"
              :class="numColor[currentRound.value.id ? currentRound.value.result : 0]"
              class="hide">
              <span />
            </div>
          </div>
          <div
            id="wprw-rcircle"
            class="wprw-pieContainer">
            <div class="wprw-pieBackground" />
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="5"
        lg="3">
        <v-card
          class="board"
          outlined>
          <v-row
            justify="space-between"
            no-gutters>
            <v-col
              cols="6">
              <prices-board :prices="prices" />
            </v-col>
            <v-col cols="6">
              <latest-rounds-board
                :num-color="numColor"
                :rounds="previousRounds.value" />
            </v-col>
          </v-row>
          <plays-board
            :colors="colors"
            :num-color="numColor"
            :rounds="previousRounds.value" />
        </v-card>
      </v-col>
      <v-snackbar
        :value="showNetworkErrorSnackbar"
        timeout="-1"
        shaped
        bottom
        vertical
        text
        color="red accent-2">
        <font-awesome-icon
          icon="exclamation-triangle"
          size="2x" />
        No internet connection, please check...
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import "@/roulette/style.css";
import "@/roulette/wheelstyle.css";
import Wheel, { WheelStatuses } from "@/roulette/wheelspin.js";
import PricesBoard from "@/components/roulette/PricesBoard.vue";
import LatestRoundsBoard from "@/components/roulette/LatestRoundsBoard.vue";
import PlaysBoard from "@/components/roulette/PlaysBoard.vue";
import Jackpot from "@/components/roulette/Jackpot.vue";
import { createRound, noMoreBets, roundOver } from "@/api/round.js";
import Game from "../../imported/Game.json";
import { fetchParams } from "@/api/games.js";
import moment from "moment";
import numeral from "numeral";
import RoundInformation from "@/components/games/roulette/RoundInformation.vue";
import { RENEW_TOKEN } from "@/store/mutations.js";
import jwtDecode from "jwt-decode";
import { MODULE_NAME as CLOCK_MODULE_NAME } from "@/store/modules/clock";
import { SET_TIME, SET_TIME_ZONE } from "@/store/modules/clock/mutations.js";
import { createNamespacedHelpers } from "vuex";

const { mapState: mapStateForClock } = createNamespacedHelpers(CLOCK_MODULE_NAME);

// Testing
const colors = {
  red: "red darken-4",
  black: "black",
  green: "green darken-4",
};

const numColor = {
  0: colors.green,
  1: colors.red,
  2: colors.black,
  3: colors.red,
  4: colors.black,
  5: colors.red,
  6: colors.black,
  7: colors.red,
  8: colors.black,
  9: colors.red,
  10: colors.black,
  11: colors.black,
  12: colors.red,
  13: colors.black,
  14: colors.red,
  15: colors.black,
  16: colors.red,
  17: colors.black,
  18: colors.red,
  19: colors.red,
  20: colors.black,
  21: colors.red,
  22: colors.black,
  23: colors.red,
  24: colors.black,
  25: colors.red,
  26: colors.black,
  27: colors.red,
  28: colors.black,
  29: colors.black,
  30: colors.red,
  31: colors.black,
  32: colors.red,
  33: colors.black,
  34: colors.red,
  35: colors.black,
  36: colors.red,
};

const prices = {
  color: {
    red: 2,
    black: 2,
    green: 35,
  },
  dozen: 3,
  even_odd: 2,
  low_high: 2,
  number: 35,
};

export default {
  name: "Board",
  components: {
    RoundInformation,
    PricesBoard,
    LatestRoundsBoard,
    PlaysBoard,
    Jackpot,
  },
  data() {
    return {
      colors,
      numColor,
      prices,
      showNetworkErrorSnackbar: false,
      previousRounds: {
        value: [],
      },
      image: null,
      currentRound: {
        value: {
          id: null,
          sequence: null,
          startTimeInMillis: null,
          closeTimeInMillis: null,
          timeLeft: null,
          noMoreBets: false,
          result: null,
          spinning: false,
        },
      },
      wheel: null,
      jackpot: {
        value: {
          enable: false,
          balance: 0,
          won: false,
        },
      },
      automaticRound: {
        consecutiveRoundsCreatedAutomatically: 0,
        secondsLeftUntilNext: null,
        creating: false,
      },
    };
  },
  channels: {
    RouletteChannel: {
      received(payload) {
        if (payload.type === "ROUND_START") {
          this.stopInactivityCountDown({ resetConsecutiveRoundsCount: true });
          const {
              round,
              token,
            } = payload,
            decodedToken = jwtDecode(token),
            {
              serverTime,
              timeZone,
            } = decodedToken.data;

          this.$store.commit(RENEW_TOKEN, {
            token,
            exp: decodedToken.exp,
          });
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME}`, moment(serverTime).
            tz(timeZone));
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME_ZONE}`, timeZone);
          this.setCurrentRound(round);
        } else if (payload.type === "RESULT_POSTED") {
          const {
              result,
              token,
            } = payload,
            decodedToken = jwtDecode(token),
            {
              serverTime,
              timeZone,
            } = decodedToken.data;

          this.$store.commit(RENEW_TOKEN, {
            token,
            exp: decodedToken.exp,
          });
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME}`, moment(serverTime).
            tz(timeZone));
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME_ZONE}`, timeZone);

          this.currentRound.value.noMoreBets = true;
          this.currentRound.value.spinning = true;
          this.currentRound.value.result = result;
          this.wheel.beginWheelSpin(result);
        } else if (payload.type === "EXTEND_ROUND") {
          const { round } = payload;
          this.setCurrentRound(round);
        }
      },
    },
  },
  computed: {
    ...mapStateForClock({
      currentTime: ({ time }) => time,
      timeZone: ({ timeZone }) => timeZone,
    }),
    progressPercentage() {
      const startTime = this.currentRound.value.startTimeInMillis,
        closingTime = this.currentRound.value.closeTimeInMillis,
        currentTime = this.currentTime;

      if (closingTime === null || currentTime >= closingTime) {
        return 100;
      }

      const roundDuration = closingTime - startTime;

      return 100 - (closingTime - currentTime) / roundDuration * 100;
    },
  },
  watch: {
    currentTime: "clockTick",
  },
  created() {
    this.fetchParams();
    window.addEventListener('online', this.onOnline)
    window.addEventListener('offline', this.onOffline)
  },
  destroyed() {
    window.removeEventListener('online', this.onOnline);
    window.removeEventListener('offline', this.onOffline);
  },
  mounted() {
    this.wheel = new Wheel();
    this.wheel.on("statusChanged", () => {
      if (this.wheel.status === WheelStatuses.COMPLETED) {
        this.prepareScreenForNextRound();
      }
    });

    this.disconnectWebsocket();
    this.connectWebsocket({ token: `Bearer ${this.$store.state.token}` });

    this.$cable.subscribe({
      channel: "RouletteChannel",
    });
  },
  methods: {
    onOnline(){
      this.showNetworkErrorSnackbar = false;
    },
    onOffline(){
      this.showNetworkErrorSnackbar = true;
    },
    stopInactivityCountDown(options = {}) {
      this.automaticRound.secondsLeftUntilNext = null;
      if (options?.resetConsecutiveRoundsCount) {
        this.automaticRound.consecutiveRoundsCreatedAutomatically = 0;
      }
    },
    beginInactivityCountDown() {
      this.automaticRound.secondsLeftUntilNext = 120;
      this.automaticRound.consecutiveRoundsCreatedAutomatically = this.automaticRound.consecutiveRoundsCreatedAutomatically + 1;
      console.info(`Consecutive rounds ${this.automaticRound.consecutiveRoundsCreatedAutomatically}`);
    },
    prepareScreenForNextRound() {
      const round = this.currentRound.value,
        previousRounds = this.previousRounds.value;

      const newRound = { ...round };
      if (previousRounds.length === 300) {
        this.previousRounds.value = [
          newRound,
          ...previousRounds.slice(0, 299),
        ];
      } else {
        this.previousRounds.value = [
          newRound,
          ...previousRounds,
        ];
      }

      setTimeout(() => {
        this.wheel.resetAnimation();
        this.$set(round, "id", null);
        this.$set(round, "sequence", null);
        this.$set(round, "startTimeInMillis", null);
        this.$set(round, "closeTimeInMillis", null);
        this.$set(round, "extended", null);
        this.$set(round, "noMoreBets", false);
        this.$set(round, "result", null);
      }, 5000);
      this.markRoundAsOver(this.currentRound.value.id);
    },
    markRoundAsOver(id) {
      roundOver(id).
        then(response => {
          // After 12 rounds created automatically logout user
          if (this.automaticRound.consecutiveRoundsCreatedAutomatically === 36) {
            this.$emit("logout");
          } else {
            this.beginInactivityCountDown();
          }

          const { jackpot } = response.data;
          if (typeof jackpot !== "undefined") {
            this.setRoundJackpot({
              enable: true,
              balance: jackpot.balance,
              won: jackpot.hasWinner,
            });
          } else {
            this.setRoundJackpot({
              enable: false,
              balance: 0,
              won: false,
            });
          }
        }).
        catch(() => {
          console.warn("An error occurred while marking round as 'Over', retrying in 3 second...");
          setTimeout(() => this.markRoundAsOver(id), 3000);
        });
    },
    clockTick(currentTime) {
      const round = this.currentRound.value,
        secondsLeftUntilNextAutomaticRound = this.automaticRound.secondsLeftUntilNext;

      if (secondsLeftUntilNextAutomaticRound !== null) {
        if (secondsLeftUntilNextAutomaticRound === 0) {
          this.createRoundAutomatically();
        } else {
          this.automaticRound.secondsLeftUntilNext = this.automaticRound.secondsLeftUntilNext - 1;
        }
      }

      if (round.id) {
        const closeTime = moment(round.closeTimeInMillis).
            tz(this.timeZone),
          duration = moment.duration(closeTime.diff(moment(currentTime).
            tz(this.timeZone))),
          formattedTimeLeft = numeral(duration.asSeconds()).
            format("00:00:00");

        if (duration.asSeconds() >= 0) {
          this.$set(round, "timeLeft", formattedTimeLeft.slice(2, formattedTimeLeft.length));
        } else if (round.noMoreBets === false) {
          this.$set(round, "timeLeft", null);
          this.currentRound.value.noMoreBets = true;
          this.updateRoundNoMoreBetsStatus();
        } else if (round.spinning === false && this.currentRound.value.result !== null) {
          this.$set(round, "spinning", true);
          this.wheel.beginWheelSpin(this.currentRound.value.result);
        }
      }
    },
    fetchParams() {
      fetchParams(Game.roulette).
        then(response => {
          const {
            round,
            jackpot,
            previousRounds,
            image,
          } = response.data;
          if (round) {
            this.setCurrentRound(round);
          } else {
            this.beginInactivityCountDown();
          }
          this.previousRounds.value = previousRounds;

          this.image = image;
          if (jackpot) {
            this.setRoundJackpot({
              enable: true,
              balance: jackpot.balance,
              won: jackpot.won,
            });
          }
        });
    },
    setCurrentRound(ongoingRound) {
      this.currentRound.value = {
        id: ongoingRound.id,
        sequence: ongoingRound.sequence,
        startTimeInMillis: ongoingRound.startTimeInMillis,
        closeTimeInMillis: ongoingRound.closeTimeInMillis,
        extended: ongoingRound.extended,
        noMoreBets: ongoingRound.noMoreBets,
        result: ongoingRound.result,
        spinning: false,
      };
    },
    setRoundJackpot(jackpot) {
      this.jackpot.value = {
        enable: jackpot.enable,
        balance: jackpot.balance,
        won: jackpot.won,
      };
    },
    updateRoundNoMoreBetsStatus() {
      noMoreBets(this.currentRound.value.id).
        catch(() => {
          console.warn("An error occurred while marking round as 'NoMoreBets', retrying in 3 second...");
          setTimeout(this.updateRoundNoMoreBetsStatus, 3000);
        });
    },
    createRoundAutomatically() {
      if (this.automaticRound.creating) {
        console.warn("Round creation in progress...");
        return;
      }
      this.automaticRound.creating = true;
      createRound().
        then(({ data }) => {
          this.stopInactivityCountDown();
          this.setCurrentRound(data.round);
        }).
        catch(() => {
          console.warn("An error occurred while trying to create a round automatically, retrying in 3 second...");
          setTimeout(this.createRoundAutomatically, 3000);
        }).
        finally(() => {
          this.automaticRound.creating = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.round-info-wrapper {
  color: white;
  position: absolute;
}

//.spin-to-win {
//  position: absolute;
//}

//@media only screen and (max-width: 876px) {
//  .spin-to-win {
//    width: 15%;
//    top: 25%;
//  }
//}

//@media only screen and (min-width: 876px) {
//  .spin-to-win {
//    width: 10%;
//    top: 15%;
//  }
//}

.board {
  background-color: transparent;
  border: 2px solid gold;
  padding: 2px;
}

.round-progress {
  position: absolute;
  top: 0;
  left: 0;
}

#result-display {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15em;
  height: 15em;
  background-color: azure;
  border-radius: inherit;
  z-index: 10;
  top: 1.9em;
  left: 1.9em;
  color: white;

  span {
    // font-size: xx-large;
    font-size: 1000%;
  }
}
</style>
