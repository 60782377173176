<template>
  <v-container
    id="prices-board"
    class="pa-0 text-center"
    fluid>
    <div class="header">
      {{ $t('views.roulette.pricesBoard.title') }}
    </div>
    <v-row no-gutters>
      <v-col cols="9">
        <v-card
          class="d-flex align-center justify-center"
          color="green"
          style="height:100%;"
          outlined>
          {{ $t('views.roulette.pricesBoard.color') }}
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          :color="colors.red"
          outlined>
          ${{ prices.color.red }}
        </v-card>
        <v-card
          :color="colors.black"
          outlined>
          ${{ prices.color.black }}
        </v-card>
        <v-card
          :color="colors.green"
          outlined>
          ${{ prices.color.green }}
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="9">
        <v-card
          color="green"
          outlined>
          {{ $t('views.roulette.pricesBoard.dozen') }}
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          color="indigo"
          outlined>
          ${{ prices.dozen }}
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="9">
        <v-card
          color="green"
          outlined>
          ${{ $t('views.roulette.pricesBoard.even_odd') }}
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          color="indigo"
          outlined>
          ${{ prices.even_odd }}
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="9">
        <v-card
          color="green"
          outlined>
          ${{ $t('views.roulette.pricesBoard.low_high') }}
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          color="indigo"
          outlined>
          ${{ prices.low_high }}
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="9">
        <v-card
          color="green"
          outlined>
          {{ $t('views.roulette.pricesBoard.number') }}
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          color="indigo"
          outlined>
          ${{ prices.number }}
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
	const colors = {
		red: 'red darken-4',
		black: 'black',
		green: 'green darken-4'
	};

	function data() {
		return {
			colors,
		};
	}

	export default {
		name: "PricesBoard",
		props: {
			prices: {
				type: Object,
				required: true,
			},
		},
		data,
	};
</script>

<style lang="scss">
	#prices-board {
		color: white;

		table, th, td {
			border: 1px solid black;
		}

		.v-card {
			color: white;
			padding: 2px;
			border: 1px black solid !important;
		}
	}
</style>
